import React from "react";
import { useEffect, useState } from "react";
import clockIcon from "../../assets/img/Clock_Icon.webp";
import alertIcon from "../../assets/img/2579410-alert-icon.webp";
import pinIcon from "../../assets/img/location-pin-svgrepo-com 3.webp";
import telIcon from "../../assets/img/contact_phone_img.webp";
import faxIcon from "../../assets/img/684832 1.webp";
import { HashLink } from "react-router-hash-link";
import ImageGallery from "../ImageGallery";
import { useMediaQuery } from "react-responsive";

function PraxisTemplate({ praxisInfos }) {
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  const [useNewOfficeTime, setUseNewOfficeTime] = useState(false);
  var dayIndexArray = { Mo: 0, Di: 1, Mi: 2, Do: 3, Fr: 4, Sa: 5, So: 5 };
  var _dayIndexArray = {
    0: "Mo",
    1: "Di",
    2: "Mi",
    3: "Do",
    4: "Fr",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });
  const isNoPc = useMediaQuery({ query: "(max-width: 66em)" });

  useEffect(() => {
    var options = {
      weekday: "short",
      timeZone: "Europe/Berlin",
    };
    var currentWeekDay = new Date()
      .toLocaleDateString("de", options)
      .replace(".", "");

    var dayIndex = dayIndexArray[currentWeekDay];

    if (currentWeekDay !== "Sa" && currentWeekDay !== "So") {
      var _currentOfficeTime =
        praxisInfos["officeTimes"][dayIndex][currentWeekDay];
      setCurrentOfficeTime(_currentOfficeTime);
    } else {
      setCurrentOfficeTime(
        "Es werden heute leider keine Sprechzeiten angeboten."
      );
    }

    setUseNewOfficeTime(praxisInfos["useNewOfficeTimes"]);
  }, [praxisInfos]);

  return (
    <section id="praxis-element">
      <div className="praxis-element-heading text-center">
        <div className="current-officeTime-header">
          <img src={clockIcon} alt="uhrIcon"></img>
          <span>{"Heutige Sprechzeiten:"}</span>
        </div>
        <div className="current-officeTime-text">{currentOfficeTime}</div>
        <div className="praxis-element-header">{praxisInfos["fakultat"]}</div>
        <div className="praxis-element-underline"></div>
        <div className="praxis-element-doctor">{praxisInfos["doctor"]}</div>
        <div className="praxis-element-title">{praxisInfos["title"]}</div>
        <HashLink
          to="#kontakt-sprechzeiten"
          className="btn"
          data-type="inverted">
          Kontakt & Sprechzeiten
        </HashLink>
      </div>
      <div className="container-gray">
        <div className="padding-block-700 padding-inline">
          <p className="praxis-element-welcome-text">
            <span className="fw-bold">Willkommen in unserer Praxis!</span>
            <br />
            Ihre Gesundheit ist unsere Priorität. Unser engagiertes Team steht
            bereit, Sie bestmöglich zu betreuen. Erfahren Sie mehr über unsere
            umfassenden Leistungen, die auf Ihre individuellen Bedürfnisse
            zugeschnitten sind.
          </p>
          <div className="praxis-element-service-wrapper">
            {praxisInfos["services"] ? (
              <>
                <div className="praxis-element-service-header">Leistungen</div>
                <div className="praxis-element-service-list">
                  {praxisInfos["services"].map((service, i) => {
                    return (
                      <div className="praxis-element-service-list-item">
                        <div className="praxis-element-service-list-divider"></div>
                        <div
                          className="praxis-element-service-list-item-text"
                          key={"item_" + i}>
                          {service}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            {praxisInfos["notes"] ? (
              <div className="praxis-element-service-note">
                <img src={alertIcon} alt="noteIcon"></img>
                <span>{praxisInfos["notes"]}</span>
              </div>
            ) : (
              <></>
            )}
            {praxisInfos["extendedNotes"] ? (
              <div className="praxis-element-service-extended-note">
                <img src={alertIcon} alt="noteIcon"></img>
                {praxisInfos["extendedNotes"].map((note, i) => {
                  return <p key={i}>{note}</p>;
                })}
                <span></span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {praxisInfos["imgList"].length > 0 ? (
            <div className="praxis-element-praxis-gallery">
              <div className="praxis-element-praxis-gallery-header">
                Unsere Praxis
              </div>
              <div className="praxis-element-praxis-gallery-wrapper">
                <ImageGallery
                  imgList={praxisInfos["imgList"]}
                  mobile={isMobile}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div id="kontakt-sprechzeiten">
        <div className="praxis-element-contact-wrapper padding-block-500 padding-inline">
          <div className="praxis-element-contact-info">
            <div className="praxis-element-contact-info-header">Kontakt</div>

            {praxisInfos["officeLocations"] ? (
              <div className="praxis-element-contact-info-group-list">
                {praxisInfos["officeLocations"].map((ol, i) => {
                  return (
                    <div className="praxis-element-contact-info-group-list-item">
                      <div className="praxis-element-contact-ot-extended-times-header">
                        {ol["city"]}
                      </div>
                      <div className="praxis-element-contact-info-item">
                        <div className="praxis-element-contact-info-card">
                          <img src={pinIcon} alt="pinIcon"></img>
                        </div>
                        <div className="praxis-element-contact-info-item-text">
                          {ol["street"]}
                          <br />
                          {ol["postalCode"] + " " + ol["city"]}
                        </div>
                      </div>
                      <div className="praxis-element-contact-info-item">
                        {isMobile ? (
                          <a href={"tel:+" + ol["phone"]}>
                            <div className="praxis-element-contact-info-card">
                              <img src={telIcon} alt="telIcon"></img>
                            </div>
                          </a>
                        ) : (
                          <div className="praxis-element-contact-info-card">
                            <img src={telIcon} alt="telIcon"></img>
                          </div>
                        )}

                        <div className="praxis-element-contact-info-item-text">
                          {ol["phone"]}
                        </div>
                      </div>
                      <div className="praxis-element-contact-info-item">
                        <div className="praxis-element-contact-info-card">
                          <img src={faxIcon} alt="faxIcon"></img>
                        </div>
                        <div className="praxis-element-contact-info-item-text">
                          {ol["fax"]}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div className="praxis-element-contact-info-item">
                  <div className="praxis-element-contact-info-card">
                    <img src={pinIcon} alt="pinIcon"></img>
                  </div>
                  <div className="praxis-element-contact-info-item-text">
                    {praxisInfos["street"]}
                    <br />
                    {praxisInfos["postalCode"] + " " + praxisInfos["city"]}
                  </div>
                </div>
                <div className="praxis-element-contact-info-item">
                  {isMobile ? (
                    <a href={"tel:+" + praxisInfos["phone"]}>
                      <div className="praxis-element-contact-info-card">
                        <img src={telIcon} alt="telIcon"></img>
                      </div>
                    </a>
                  ) : (
                    <div className="praxis-element-contact-info-card">
                      <img src={telIcon} alt="telIcon"></img>
                    </div>
                  )}

                  <div className="praxis-element-contact-info-item-text">
                    {praxisInfos["phone"]}
                  </div>
                </div>
                <div className="praxis-element-contact-info-item">
                  <div className="praxis-element-contact-info-card">
                    <img src={faxIcon} alt="faxIcon"></img>
                  </div>
                  <div className="praxis-element-contact-info-item-text">
                    {praxisInfos["fax"]}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="praxis-element-contact-ot">
            <div className="praxis-element-contact-ot-header">
              <img src={clockIcon} alt="uhr"></img>
              <span>Sprechzeiten</span>
            </div>

            {praxisInfos["officeLocations"] ? (
              <div className="praxis-element-contact-ot-extended-times-wrapper">
                {praxisInfos["officeLocations"].map((ol) => {
                  return (
                    <div className="praxis-element-contact-ot-extended-times">
                      <div className="praxis-element-contact-ot-extended-times-header">
                        {ol["city"]}
                      </div>
                      <div className="praxis-element-contact-ot-times">
                        {ol["officeTimes"].map((ot, i) => {
                          if (ot[_dayIndexArray[i]]) {
                            return (
                              <div
                                className="praxis-element-contact-ot-time-element"
                                key={i}>
                                {ot[_dayIndexArray[i]]}
                              </div>
                            );
                          }
                          return <></>;
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : useNewOfficeTime ? (
              isNoPc ? (
                <div className="praxis-element-contact-ot-times">
                  {praxisInfos["officeTimes1"].map((ot, i) => {
                    return (
                      <div className="praxis-element-contact-ot-times-element">
                        <p className="praxis-element-contact-ot-times-element-header">
                          {ot["displayName"]}
                        </p>
                        <div className="praxis-element-contact-ot-times-element-ot-wrapper">
                          {ot[_dayIndexArray[i]].map((otElement) => {
                            return (
                              <div className="praxis-element-contact-ot-times-element-ot">
                                <p>{otElement.time}</p>
                                <p>{otElement.note}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="praxis-element-contact-ot-times">
                  <table className="praxis-element-contact-ot-table">
                    <tr className="praxis-element-contact-ot-table-header">
                      {praxisInfos["officeTimes1"].map((ot, i) => {
                        return (
                          <th className="praxis-element-contact-ot-table-header-value">
                            {ot["displayName"]}
                          </th>
                        );
                      })}
                    </tr>
                    <tr className="praxis-element-contact-ot-table-tr">
                      {praxisInfos["officeTimes1"].map((ot, i) => {
                        if (ot[_dayIndexArray[i]][0]) {
                          return (
                            <td className="praxis-element-contact-ot-table-td">
                              <p>{ot[_dayIndexArray[i]][0].time}</p>
                              <p>{ot[_dayIndexArray[i]][0].note}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr className="praxis-element-contact-ot-table-tr">
                      {praxisInfos["officeTimes1"].map((ot, i) => {
                        if (ot[_dayIndexArray[i]][1]) {
                          return (
                            <td className="praxis-element-contact-ot-table-td">
                              <p>{ot[_dayIndexArray[i]][1].time}</p>
                              <p>{ot[_dayIndexArray[i]][1].note}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr className="praxis-element-contact-ot-table-tr">
                      {praxisInfos["officeTimes1"].map((ot, i) => {
                        if (ot[_dayIndexArray[i]][2]) {
                          return (
                            <td className="praxis-element-contact-ot-table-td">
                              <p>{ot[_dayIndexArray[i]][2].time}</p>
                              <p>{ot[_dayIndexArray[i]][2].note}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                    <tr className="praxis-element-contact-ot-table-tr">
                      {praxisInfos["officeTimes1"].map((ot, i) => {
                        if (ot[_dayIndexArray[i]][3]) {
                          return (
                            <td className="praxis-element-contact-ot-table-td">
                              <p>{ot[_dayIndexArray[i]][3].time}</p>
                              <p>{ot[_dayIndexArray[i]][3].note}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  </table>
                </div>
              )
            ) : (
              <div className="praxis-element-contact-ot-times">
                {praxisInfos["officeTimes"].map((ot, i) => {
                  if (ot[_dayIndexArray[i]]) {
                    return (
                      <div
                        className="praxis-element-contact-ot-time-element"
                        key={i}>
                        {ot[_dayIndexArray[i]]}
                      </div>
                    );
                  }
                })}
              </div>
            )}

            {praxisInfos["officeTimesNote"] ? (
              <div className="praxis-element-contact-ot-note">
                <p>Akutsprechstunde</p>
                <p>{praxisInfos["officeTimesNote"]}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PraxisTemplate;
